/* eslint-disable */
import { useEffect, useState } from 'react';
import logo from 'assets/logo.png';
import checked from 'assets/icons/checked.png';
import del from 'assets/icons/delete.png';
import white from 'assets/icons/white.png';
import { Page, Text, Image, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Loading } from '@app/components/common/Loading';
import moment from 'moment';

// Create styles

const questionList = [
  'Alcool Drogue',
  'Protections Auditives',
  'Jugulaire',
  'Extincteur au poste',
  'Electroportatif',
  'Rallonge électrique',
  'Stop & devoir d’alerte',
  'Protections collectives  (conformité)',
  'Protections collectives (passer à travers)',
  'Superposition de taches',
  'Echelle/ escabeau',
  'Levage (berceau)',
  'Levage (position chef de manœuvre)',
];

const questionList2 = [
  'Déplacement étais',
  'Travaux par point chaud & matériau inflammable',
  'Nacelle et balisage zone intervention',
  'Electroportatif & formation au poste',
  'Evacuation & chute objet',
  'Elingage & formation',
  'Habilitation Electrique',
  'Blessure à la main',
  'Echelle',
  'Accès et zone d’intervention',
  'Rallonge électrique 2',
  'Harnais et nacelle',
  'Matériel en bon état',
];

// Create Document Component
const MyDocument = ({ data }) => {
  const { firstName, lastName, dateOfBirth, contributor, workEntity, employer, fail, time, attempt, createdAt } = data;

  const MyDocument: any = Document;
  const MyPage: any = Page;

  return (
    <MyDocument>
      <MyPage size="A4" style={styles.page}>
        <View style={styles.head}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.titre}>Résultats quiz dématérialisé Santé Sécurité </Text>
        </View>
        <View style={styles.body}>
          <View style={styles.section}>
            <Text style={styles.label}>Prénom :</Text>
            <Text style={styles.subtitle}>{firstName}</Text>
          </View>
          <View style={[styles.section, styles.wrapper]}>
            <Text style={styles.label}>Nom :</Text>
            <Text style={styles.subtitle}>{lastName}</Text>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.section}>
              <Text style={styles.label}>Résultat </Text>
              <Text style={styles.subtitle}>{fail > 1 ? 'Échec' : 'Réussite'}</Text>
            </View>
          </View>
          <View style={styles.group}>
            <View style={[styles.sectionGrp, styles.wrapper]}>
              <Text style={styles.label}>Date de naissance :</Text>
              <Text style={styles.subtitle}>{dateOfBirth}</Text>
            </View>
            <View style={[styles.sectionGrp, styles.wrapper]}>
              <Text style={styles.label}>Intervenant :</Text>
              <Text style={styles.subtitle}>{contributor}</Text>
            </View>
          </View>
          <View style={styles.group}>
            <View style={[styles.sectionGrp, styles.wrapper]}>
              <Text style={styles.label}>Entité BBF :</Text>
              <Text style={styles.subtitle}>{workEntity}</Text>
            </View>
          </View>
          <View style={styles.group}>
            <View style={[styles.section, styles.wrapper]}>
              <Text style={styles.label}>Date du test :</Text>
              <Text style={styles.subtitle}>{moment(createdAt).format('DD/MM/YYYY')}</Text>
            </View>
            <View style={[styles.section, styles.wrapper]}>
              <Text style={styles.label}>Nombre de tentative(s) :</Text>
              <Text style={styles.subtitle}>{attempt}</Text>
            </View>
            <View style={[styles.section, styles.wrapper]}>
              <Text style={styles.label}>Nombre d'erreur(s) :</Text>
              <Text style={styles.subtitle}>{fail}</Text>
            </View>
            <View style={[styles.section, styles.wrapper]}>
              <Text style={styles.label}>Durée du test :</Text>
              <Text style={styles.subtitle}>{time}</Text>
            </View>
          </View>

          <View style={[styles.section, styles.wrapper]}>
            <Text style={styles.subtitle}>Questions : </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.ContainerQuestion}>
              {questionList.map((item, index) => (
                <View key={'__v_' + index} style={styles.item}>
                  <Image
                    style={styles.icon}
                    src={data['Q' + (index + 1)] === true ? checked : data['Q' + (index + 1)] === false ? del : white}
                  />
                  <Text style={styles.questionText} key={index}>
                    {item}
                  </Text>
                </View>
              ))}
            </View>
            <View style={styles.ContainerQuestion}>
              {questionList2.map((item, index) => (
                <View key={'__vv_' + index} style={styles.item}>
                  <Image
                    style={styles.icon}
                    src={data['Q' + (index + 14)] === true ? checked : data['Q' + (index + 14)] === false ? del : white}
                  />
                  <Text style={styles.questionText}>{item}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      </MyPage>
    </MyDocument>
  );
};

const PdfPage = (props: any) => {
  const [state, setstate] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      setLoading(true);
      const val = await localStorage.getItem('_generate_pdf_');
      if (val == null && !Object.keys(state).length) {
        (window || document).location.href = '/';
      }
      setstate(JSON.parse(val || '{}'));
      await localStorage.removeItem('_generate_pdf_');
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    init();
  }, []);

  return (
    <div style={styles.pageView}>
      {loading ? (
        <Loading />
      ) : (
        <PDFViewer width="100%" height="100%">
          <MyDocument data={state} />
        </PDFViewer>
      )}
    </div>
  );
};
const styles = StyleSheet.create({
  pageView: {
    backgroundColor: '#0000',
    height: '99vh',
  },
  icon: {
    width: 14,
    height: 14,
    padding: 1,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFF',
  },
  section: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    // flexGrow: 1,
  },
  sectionGrp: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: 170,
  },
  group: {
    flexDirection: 'row',
  },
  head: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    marginTop: 30,
    height: 70,
  },
  body: {
    marginHorizontal: 45,
    marginTop: 20,
  },
  logo: {
    width: 100,
    height: 'auto',
  },
  titre: {
    fontSize: 14,
    marginLeft: 50,
    color: '#0aa180',
    fontWeight: 700,
  },
  wrapper: {
    marginTop: 10,
  },
  ContainerQuestion: {
    width: '49%',
    padding: 10,
  },
  questionText: {
    fontSize: 10,
    marginLeft: 5,
  },
  subtitle: {
    fontSize: 11,
    marginBottom: 5,
    marginRight: 2,
  },
  label: {
    fontSize: 11,
    marginBottom: 5,
    color: 'orange',
    marginRight: 2,
  },
});

export default PdfPage;
