import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { H4 } from '@app/components/common/typography/H4/H4';
import { UserOutlined } from '@ant-design/icons';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  return !user ? (
    <div>
      <H4>{`${user.firstName} ${user.lastName}`}</H4>
    </div>
  ) : (
    <Dropdown overlay={<ProfileOverlay />} disabled trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Avatar size="large" icon={<UserOutlined />} alt="Avatar" shape="square" />
        </Col>
        {isTablet && (
          <Col>
            <H4>{`${user.firstName} ${user.lastName}`}</H4>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  );
};
