import React from 'react';
import { DropdownCollapse } from '@app/components/header/Header.styles';
import { useTranslation } from 'react-i18next';
// import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { ThemePicker } from '../ThemePicker/ThemePicker';
import * as S from './SettingsOverlay.styles';
import { Link } from 'react-router-dom';
import { H5 } from '@app/components/common/typography/H5/H5';

export const SettingsOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <S.SettingsOverlayMenu mode="inline" selectable={false} {...props}>
      <DropdownCollapse bordered={false} expandIconPosition="right" ghost defaultActiveKey="themePicker">
        {/* <DropdownCollapse.Panel header={t('header.changeLanguage')} key="languagePicker">
          <LanguagePicker />
        </DropdownCollapse.Panel> */}
        <DropdownCollapse.Panel header={t('header.changeTheme')} key="themePicker">
          <ThemePicker />
        </DropdownCollapse.Panel>
        <div style={{ margin: 10, marginLeft: 15 }}>
          <Link to="/logout">
            <H5>{t('header.logout')}</H5>
          </Link>
        </div>
      </DropdownCollapse>
      {/* {isPWASupported && (
        <S.PwaInstallWrapper>
          <Button block type="primary" onClick={() => event && (event as BeforeInstallPromptEvent).prompt()}>
            {t('common.pwa')}
          </Button>
        </S.PwaInstallWrapper>
      )} */}
    </S.SettingsOverlayMenu>
  );
};
