import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '@app/components/router/routeName';
import { LoginFormData } from './type';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';

export const useLoginPageCtr = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        notificationController.error({
          message: 'Adresse e-mail ou mot de passe incorrect. Veuillez vérifier vos informations et réessayer',
        });
        setLoading(false);
      });
  };

  const navigateToSignup = () => navigate(url.noAuth.signup);

  return {
    handleSubmit,
    loading,
    navigateToSignup,
  };
};
