import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/Authetification/LoginPage';
import NewPasswordPage from '@app/pages/Authetification/NewPasswordPage';
// import LockPage from '@app/pages/Authetification/LockPage';
import PdfPage from '@app/pages/pdfPage/pdfPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import ProfileLayout from '@app/components/profile/ProfileLayout';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ListResultPage = React.lazy(() => import('@app/pages/ListResultPage/ListResultPage'));
const ListLogsPage = React.lazy(() => import('@app/pages/ListLogsPage/ListLogsPage'));
const ListUserPage = React.lazy(() => import('@app/pages/ListUserPage/ListUserPage'));
const Logout = React.lazy(() => import('./Logout'));

const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
// const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));

export const DASHBOARD_PATH = '/';

// Maps

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
// const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);
// Profile

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const ListResult = withLoading(ListResultPage);
const ListLogs = withLoading(ListLogsPage);
const ListUser = withLoading(ListUserPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="pdf"
          element={
            <RequireAuth>
              <PdfPage />
            </RequireAuth>
          }
        />
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          {/* <Route path="/dashboard" element={<MedicalDashboard />} /> */}
          <Route path="/logs" element={<ListLogs />} />
          <Route path="/" element={<ListResult />} />
          {/* <Route path="result" element={<ListResult />} /> */}
          {/* <Route path="list" element={<ListQuizz />} /> */}
          <Route path="user" element={<ListUser />} />

          <Route path="server-error" element={<ServerError />} />

          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="my-log" element={<Payments />} />
          </Route>
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          {/* <Route path="/reset-pw" element={<NewPasswordPage />} /> */}
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="signup" element={<SignUpPage />} /> */}
          {/* <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          /> */}
          {/* <Route path="forgot-password" element={<ForgotPasswordPage />} /> */}
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
