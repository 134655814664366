import React, { useMemo } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import { RightOutlined } from '@ant-design/icons';
import welife from 'assets/welife.png';
import welifeLite from 'assets/welifeLite.png';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { mobileOnly, tabletOnly } = useResponsive();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={mobileOnly ? 0 : 80}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.SiderContent>
          {!mobileOnly && !tabletOnly && (
            <S.CollapseDesButton
              shape="circle"
              size="small"
              $isCollapsed={isCollapsed}
              icon={<RightOutlined rotate={isCollapsed ? 0 : 180} />}
              onClick={toggleSider}
            />
          )}
          <SiderMenu isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        </S.SiderContent>
        <img
          src={isCollapsed ? welifeLite : welife}
          alt="welife"
          width={isCollapsed ? 42 : 120}
          style={{
            position: 'absolute',
            bottom: 10,
          }}
        />
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
