import React from 'react';
import { HomeOutlined, UsergroupAddOutlined, SecurityScanOutlined, UserOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigationAdmin: SidebarNavigationItem[] = [
  {
    title: 'common.result.menu',
    key: 'menu-result',
    icon: <HomeOutlined />,
    url: '/',
  },
  {
    title: 'common.users',
    key: 'menu-user',
    icon: <UsergroupAddOutlined />,
    url: '/user',
  },
  {
    title: 'common.logs',
    key: 'menu-dashboard',
    icon: <SecurityScanOutlined />,
    url: '/logs',
  },
  {
    title: 'Profile',
    key: 'menu-profile',
    icon: <UserOutlined />,
    url: '/profile',
  },
];

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.result.menu',
    key: 'menu-result',
    icon: <HomeOutlined />,
    url: '/',
  },
  {
    title: 'Profile',
    key: 'menu-profil',
    icon: <UserOutlined />,
    url: '/profile',
  },
];

export const sidebarNavigationMulty: SidebarNavigationItem[] = [
  {
    title: 'common.result.menu',
    key: 'menu-result',
    icon: <HomeOutlined />,
    url: '/',
  },
];
