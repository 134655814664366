import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user.user);
  // console.log(' ********************** RequireAuth ****************  ', status);

  const token = useAppSelector((state) => state.auth.token);

  return !token ? (
    <Navigate to="/auth/login" replace />
  ) : user.status.id === 3 ? (
    <Navigate to="/auth/new-password" replace />
  ) : user?.login?.mfa && user.login?.validMail && user?.login?.need ? (
    <Navigate to="/auth/security-code" replace />
  ) : (
    <>{children}</>
  );
};

export default RequireAuth;
